import React, { useEffect } from "react"
import styled, { ThemeProvider } from 'styled-components'
import { fontSize, layout, space } from 'styled-system'
import { Helmet } from 'react-helmet'
import { useTranslation } from "react-i18next"

import theme from '../components/theme'
import '../components/global.css'
import Nav from '../components/Nav'
import Heading from '../components/Heading'
import SubHeading from '../components/SubHeading'
import Product from '../components/Product'
import Products from '../components/Products'
import Clients from '../components/Clients'
import Footer from '../components/Footer'
import { parallax } from '../components/library'
import { withTranslate } from '../i18n/withTranslate'

const Box = styled.div`
  width: 100%;
`
const Header = styled.header`
  max-width: 1600px;
  margin: 0 auto;

  ${layout}
  ${space}
`
const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  ${layout}
  ${space}
`
const PlaneAndTruck = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
`
const BaseImage = styled.div`
  width: 50%;
  overflow: hidden;
  margin: 2%;
  padding: 2%;

  ${layout}
`
const PlaneWrapper = styled(BaseImage)`
`
const TruckWrapper = styled(BaseImage)`
  margin-top: 150px;

  ${space}
`
const BoatWrapper = styled.div`
  width: 70%;
  overflow: hidden;

  ${layout}
  ${space}
`
const ImageBoat = styled.img`
  height: 100%;
  width: auto;

  ${space}
`
const HeadImage = styled.img`
  width: 100%;

  ${space}
  ${layout}
`
const BoatPlaneImage = styled.img`
  width: 100%;

  ${space}
`
// const Separator = styled.div`
//   ${space}
// `
const Article = styled.article`
`
const Introduction = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  text-align: center;
  margin: 100px auto;
  line-height: 1.8;

  ${space}
  ${layout}
  ${fontSize}
`

const Home = ({ t }) => {
  const { i18n } = useTranslation()
  
  let currentLanguage = i18n.language.slice(0, 2).toLocaleLowerCase()

  if (currentLanguage === 'fr')
  {
    i18n.changeLanguage('en')
    currentLanguage = 'en'
  }

  function onLoadPort() {
    const imagePortWrapper = document.querySelector('.imagePortWrapper')
    const imagePort = document.querySelector('.imagePort')
    const imagePortMob = document.querySelector('.imagePortMob')

    if (window.getComputedStyle(imagePort).display !== 'none') {
      imagePort.classList.add('parallaxOn')
      parallax(imagePort, { wrapper: imagePortWrapper, reverse: true })
    }

    if (window.getComputedStyle(imagePortMob).display !== 'none') {
      imagePortMob.classList.add('parallaxOn')
      parallax(imagePortMob, { wrapper: imagePortWrapper, reverse: true })
    }

  }

  function onLoadBoat() {
    const imageBoat = document.querySelector('.imageBoat')
    imageBoat.classList.add('parallaxOn')
    const imageBoatWrapper = document.querySelector('.imageBoatWrapper')

    parallax(imageBoat, { wrapper: imageBoatWrapper, direction: 'left', speed: .15 })
    // parallax(imageBoatWrapper, { direction: 'left', speed: .1 })
  }

  function onLoadPlane() {
    const imagePlane = document.querySelector('.imagePlane')
    imagePlane.classList.add('parallaxOn')
    const planeWrapper = document.querySelector('.planeWrapper')
    parallax(imagePlane, { wrapper: planeWrapper, reverse: false })
  }

  function onLoadTruck() {
    const imageTruck = document.querySelector('.imageTruck')
    imageTruck.classList.add('parallaxOn')
    const truckWrapper = document.querySelector('.truckWrapper')
    parallax(imageTruck, { wrapper: truckWrapper, reverse: true, speed: .15 })
  }

  useEffect(() => {
    const imagePort = document.querySelector('.imagePort')
    const imagePortMob = document.querySelector('.imagePortMob')
    const imageBoat = document.querySelector('.imageBoat')
    const imagePlane = document.querySelector('.imagePlane')
    const imageTruck = document.querySelector('.imageTruck')

    if (!imagePortMob.classList.contains('parallaxOn')) {
      onLoadPort()
    }
    if (!imagePort.classList.contains('parallaxOn')) {
      onLoadPort()
    }
    if (!imageBoat.classList.contains('parallaxOn')) {
      onLoadBoat()
    }
    if (!imagePlane.classList.contains('parallaxOn')) {
      onLoadPlane()
    }
    if (!imageTruck.classList.contains('parallaxOn')) {
      onLoadTruck()
    }

  }, [])

  return (
    <Box>
      <Helmet>
        <html lang={i18n.language} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title>{t('title')}</title>
        <link rel="canonical" href="https://socieci.com" />
        <link rel="alternate" hreflang="fr" href="https://socieci.com/fr" />
        <link rel="alternate" hreflang="en" href="https://socieci.com/en" />
        <link rel="alternate" hreflang="x-default" href="https://socieci.com" />

        <meta name="description" content={t('description')} />
        <meta property="og:locale" content={i18n.language} />
        {currentLanguage === 'en' ?
          <meta property="og:locale:alternate" content="fr_FR" />
          :
          <meta property="og:locale:alternate" content="en_US" />
        }
        <meta property="og:title" content={t('title')} />
        <meta property="og:description" content={t('description')} />
        <meta property="og:url" content="https://socieci.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Socieci" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <Nav />

        <Header
          px={['20px', '20px', '30px', '40px']}
        >
          <Heading
            pt={['60px', '60px', '60px', '120px']}
            pb={['80px', '80px', '100px', '180px']}
          >
            {t('heading')}

            <SubHeading>
              {t('subheading')}
            </SubHeading>
          </Heading>

          <ImageContainer
            className="imagePortWrapper"
            height={['250px', '250px', '300px', '350px']}
          >
            <HeadImage
              onLoad={onLoadPort}
              className="imagePortMob"
              mt={['100px', '80px', '60px', '0px']}
              display={['block', 'block', 'none', 'none']}
              src="images/port-container-mobile.jpg"
            />
            <HeadImage
              onLoad={onLoadPort}
              className="imagePort"
              display={['none', 'none', 'block', 'block']}
              src="images/port-container.jpg"
            />
          </ImageContainer>
        </Header>

        <Article>
          <Introduction
            width={[.9, .8, .7, .4]}
            fontSize={[13, 16, 18, 20]}
            py={['20px', '20px', '30px', '40px']}
            px={['20px', '20px', '30px', '0px']}
          >
            {t('introduction')}
          </Introduction>

          <Clients />

          <Heading>
            {t('metals')}
          </Heading>

          <Products>
            <Product
              name={t("steelRebars")}
              image="images/steel-rebars-min.png"
            />
            <Product
              name={t("steelCoil")}
              image="images/steel-coil-min.png"
            />
            <Product
              name={t("roundTubes")}
              image="images/round-tubes-min.png"
            />
            <Product
              name={t("squareTubes")}
              image="images/square-tubes-min.png"
            />
            <Product
              name={t("beamChannels")}
              image="images/beam-channel-angle-min.png"
            />
            <Product
              name={t("steelBars")}
              image="images/steel-bars-min.png"
            />
          </Products>

          <Heading pt={['110px', '120px', '130px', '140px']}>
            {t('otherProducts')}

          </Heading>

          <Products>
            <Product
              name={t("cement")}
              color="#545454"
              backColor="#f1f1f1"
              image="images/cement-min.jpg"
            />
            <Product
              name={t("rice")}
              color="#696155"
              backColor="#f1efec"
              image="images/rice-min.jpg"
            />
            <Product
              name={t("sugar")}
              color="#695840"
              backColor="#eae2d7"
              image="images/sugar-min.jpg"
            />
          </Products>

          {/* <Separator pt={['100px', '100px', '140px', '200px']} /> */}

          <Heading
            pt={['110px', '120px', '130px', '140px']}
            mx="auto"
            width={['80%', '60%', '50%', '40%']}
          >
            {t('transport')}
          </Heading>

          <BoatWrapper
            className="imageBoatWrapper"
            height={['150px', '200px', '300px', '400px']}
            width={['75%', '70%', '70%', '70%']}
            ml={['25%', '30%', '30%', '30%']}
          >
            <ImageBoat
              onLoad={onLoadBoat}
              className="imageBoat"
              ml={['-50%', '-20%', '0%', '0%']}
              src="images/boat.jpg"
            />
          </BoatWrapper>

          <PlaneAndTruck>
            <PlaneWrapper
              className="planeWrapper"
              height={['200px', '300px', '500px', '600px']}
            >
              <BoatPlaneImage
                onLoad={onLoadPlane}
                className="imagePlane"
                src="images/plane.jpg"
              />
            </PlaneWrapper>

            <TruckWrapper
              className="truckWrapper"
              height={['200px', '300px', '500px', '600px']}
            >
              <BoatPlaneImage
                onLoad={onLoadTruck}
                mt={['-10%', '-15%', '-5%', '-10%']}
                className="imageTruck"
                src="images/truck.jpg"
              />
            </TruckWrapper>
          </PlaneAndTruck>
        </Article>

        <Footer />
      </ThemeProvider>
    </Box>
  )
}


export default withTranslate(Home)
